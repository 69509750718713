import { Router as R, Route, Switch } from "wouter";
import { MainProps } from "./_model";
export default function Router(props: MainProps) {
  const bases = Object.entries(props.bases ?? {});
  return (
    <R>
      <Switch>
        {bases.map(([path, component], index) => {
          return (
            <Route key={`ROUTER_BASE::${path}::${index}`} path={path} nest>
              {component}
            </Route>
          );
        })}
        {/* don't add 'nest'  */}
        <Route>{props.root}</Route>
      </Switch>
    </R>
  );
}
